<!-- 个人中心 -->
<template>
  <div id="refundApplicationEdit">
    <div class="main">
      <div>
        <!-- 头部地理位置和搜索框 -->
        <div class="head">
          <!-- 顶部NavBar 开始 -->
          <van-nav-bar
            :title="$t('refundApplication.title')"
            :left-text="$t('main.return')"
            left-arrow
            :right-text="rightText"
            style="background-color: rgba(255,255,255,0);"
            @click-left="onClickLeft"
            @click-right="onClickRight"
          />
        </div>
      </div>
      <div class="outboxstyle">
        <div style="padding: 16px 12px;">
          <van-form class="from" @submit="onSubmit">
            <div class="getbox">
              <div class="paymonry">{{ $t('addApplication.type') }}</div>
              <!--        下拉选择类型        -->
              <van-field
                readonly
                clickable
                :label="$t('addApplication.type')"
                label-width="3rem"
                :value="temp.accountType"
                :placeholder="$t('addApplication.typeInput')"
                :rules="[{ required: true, message: $t('addApplication.typeInput') }]"
                @click="showPicker = true"
              />
              <!--      选择下拉      -->
              <van-popup v-model="showPicker" round position="bottom">
                <van-picker
                  show-toolbar
                  :columns="accountTypeList"
                  @cancel="showPicker = false"
                  @confirm="onConfirm"
                />
              </van-popup>
              <!--      账户信息        -->
              <div class="paymonry">{{ $t('addApplication.accountInformation') }}</div>
              <van-field
                v-model="temp.accountName"
                :label="$t('addApplication.name')"
                label-width="3rem"
                :placeholder="$t('addApplication.nameInput')"
                style="padding-top: 12px;padding-bottom: 12px;"
                :rules="[{ required: true, message: $t('addApplication.nameInput') }]"
              />
              <!--       支付宝账号       -->
              <van-field
                v-model="temp.accountNo"
                :label="$t('addApplication.ali')"
                label-width="3rem"
                :placeholder="$t('addApplication.aliInput')"
                style="padding-top: 12px;padding-bottom: 12px;"
                :rules="[{ required: true, message: $t('addApplication.aliInput') }]"
              />
              <van-field
                v-if="temp.accountType === '02'"
                v-model="temp.accountType"
                label-width="3rem"
                :label="$t('addApplication.sure')"
                :placeholder="$t('addApplication.bank')"
                style="padding-top: 12px;padding-bottom: 12px;"
                :rules="[{ required: true, message: $t('addApplication.bank') }]"
              />
              <!--      身份证号        -->
              <van-field
                v-if="temp.accountType === '银行卡'"
                v-model="temp.idNum"
                label-width="3rem"
                :label="$t('addApplication.idNum')"
                :placeholder="$t('addApplication.idNumInput')"
                style="padding-top: 12px;padding-bottom: 12px;"
                :rules="[
                  { required: true, message: $t('addApplication.idNumInput')},
                  { pattern: /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/, message: $t('addApplication.idNumError'), trigger: 'blur' }
                ]"
              />
              <!--      开户行        -->
              <van-field
                v-if="temp.accountType === '银行卡'"
                v-model="temp.openBank"
                label-width="3rem"
                :label="$t('addApplication.openBank')"
                :placeholder="$t('addApplication.openBankInput')"
                style="padding-top: 12px;padding-bottom: 12px;"
                :rules="[{ required: true, message: $t('addApplication.openBankInput') }]"
              />
            </div>
            <div class="bottom">
              <van-button type="info" size="large" class="bottombutton" native-type="submit">{{ $t('main.save') }}</van-button>
            </div>
          </van-form>
        </div>

      </div>
      <!-- 回到顶部按钮 -->
      <v-top />
    </div>
  </div>
</template>
<script>
import { getPayType, savePayTypeInfo, updatePayTypeInfo, personCenterAccountDelelte } from '@/api/personCenter'
import store from '@/store'
import { Dialog, Toast } from 'vant'
export default {
  name: 'RefundApplicationEdit',
  data() {
    return {
      form: {
        oldPass: '',
        newPass: '',
        confirmPass: '',
        accountNum: '',
        schoolCode: '',
        dataSource: '',
        userId: ''
      },
      imageL: [
        require('../../assets/newImages/add.png'),
        require('../../assets/newImages/alipay.png'),
        require('../../assets/newImages/wechatpay.png'),
        require('../../assets/newImages/unionpay.png'),
        require('../../assets/newImages/usersuggestion.png'),
        require('../../assets/newImages/user.png'),
        require('../../assets/newImages/refound.png')
      ],
      payCodeList: [],
      accountTypeList: [],
      twiceList: [],
      temp: {},
      showPicker: false,
      dataSource: '',
      schoolCode: '',
      rightText: ''
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  created() {
    this.temp = this.$route.query.payWayitem
    if (this.temp.accountType === '03') {
      this.temp.accountType = '银行卡'
    }
    if (this.temp.edit === true) {
      this.rightText = this.$t('main.delete')
    } else {
      this.rightText = ''
    }
  },
  mounted() {
    this.getPayTypeList()
    this.schoolCode = store.state.settings.schoolCode
    this.dataSource = store.state.settings.dataSource
  },
  methods: {
    // 返回
    onClickLeft() {
      this.$router.push({ path: '/refundApplication' })
    },
    // 删除
    onClickRight() {
      if (this.temp.edit === false) {
        return false
      } else {
        Dialog.confirm({
          title: this.$t('main.tips'),
          message: this.$t('addApplication.msg')
        })
          .then(() => {
            // on confirm
            personCenterAccountDelelte(this.temp.id).then(() => {
              Toast.success(this.$t('addApplication.sure'))
              this.$router.push({ path: '/refundApplication' })
            }).catch(error => {
              Toast.fail(error.message)
            })
          })
          .catch(() => {
            // on cancel
            Toast.fail(this.$t('addApplication.cancel'))
          })
      }
    },
    // 带参数跳转页面
    dealwith(item) {
      this.$router.push({
        path: '/login'
      })
      store.dispatch('user/logout').then(() => {
        location.reload() // 为了重新实例化vue-router对象 避免bug
      })
    },
    // 下拉
    onConfirm(value) {
      if (value === '银行卡') {
        this.$set(this.temp, 'accountType', '银行卡')
        console.log(this.temp.accountType)
      } else {
        this.temp.accountType = value
      }
      this.showPicker = false
    },
    // 下拉
    getPayTypeList() {
      this.twiceList = []
      this.accountTypeList = []
      const partnerId = 'PT000004'
      getPayType(partnerId).then(response => {
        this.twiceList = response.data
        for (let i = 0; i < response.data.length; i++) {
          this.accountTypeList.push(response.data[i].name)
          if (this.temp.accountType === response.data[i].code) {
            this.$set(this.temp, 'accountType', response.data[i].name)
          }
        }
        this.accountTypeList.push('银行卡')
      }).catch(error => {
        Toast.fail(error.message)
      })
    },
    // 保存
    onSubmit() {
      for (let i = 0; i < this.twiceList.length; i++) {
        if (this.temp.accountType === this.twiceList[i].name) {
          this.temp.accountType = this.twiceList[i].code
        }
      }
      if (this.temp.accountType === '银行卡') {
        this.temp.accountType = '03'
      }
      if (this.temp.edit === false) {
        savePayTypeInfo(
          this.schoolCode,
          this.dataSource,
          this.temp.accountType,
          this.temp.accountName,
          this.temp.accountNo,
          // 银行卡添加时候多传得信息
          this.temp.openBank,
          this.temp.idNum
        ).then(() => {
          Toast.success(this.$t('msg.saveSuccess'))
          this.$router.push({ path: '/refundApplication' })
        }).catch(error => {
          Toast.fail(error.message)
        })
      } else if (this.temp.edit === true) {
        updatePayTypeInfo(
          this.schoolCode,
          this.dataSource,
          this.temp.accountType,
          this.temp.accountName,
          this.temp.accountNo,
          this.temp.id
        ).then(() => {
          Toast.success(this.$t('msg.saveSuccess'))
          this.$router.push({ path: '/refundApplication' })
        }).catch(error => {
          Toast.fail(error.message)
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
#refundApplicationEdit {
  .van-cell {
  line-height: 30px;
  }
  .van-icon__image {
    width: 40px;
    height: 30px;
}
  position: relative;
  background-color: #eff6ff;
  .head {
    height: 84px;
    width: 100%;
    background:linear-gradient(135deg, #3793e5 0%, #4046a6 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  // padding: 0px 10px 10px 10px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  width: 100%;
  height: 100%;
}
.getbox{
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0 6px #dde9f3;
  background:#ffffff;
}
.boxstyle {
  margin: 10px 15px 0 15px;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px;
  box-shadow: 0px 4px 10px #dde9f3;
  p{
    font-size:16px;
    font-weight:700;
    margin: 20px;
  }
}
.buttontext{
  width:90px;
  border-radius:5px
}
.bottom{
  position: fixed;
  bottom:0;
  left: 0;
  width: 100%;
  background: #ffffff;
  height: 80px;
  margin-top:20px;
}
.bottombutton{
  margin: 20px;
  width:90%;
  height: 40px;
}
.van-cell__value {
  span{
    color: #333333;
  }
}
.paymonry{
  color:#2E2E2E;
  border-left: 4px #1989fa solid;
  padding-left: 10px;
  margin: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  span{
    color: red;
    float: right;
  }
}
</style>
<style>
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
[class*='van-hairline']::after {
    border: none;
}
.van-nav-bar {
  height: 80px;
}
.from{
  height: 8.01333rem;
  width: 100%;
  overflow:auto;
}
</style>
