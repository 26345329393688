import request from '@/utils/request'
import store from '@/store'

// 获取常量学校代码|数据来源
const dataSource = store.state.settings.dataSource
const schoolCode = store.state.settings.schoolCode
const proModelUrl = 'transfer'

// 查询
export function getPersonCenterAccountList() {
  return request({
    url: 'common/com/transfer/list/' + dataSource + '/' + schoolCode,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      return data
    }]
  })
}

// 下拉框
export function getPayType() {
  return request({
    url: 'pay/channel/getChannelList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      'proModelUrl': proModelUrl
    }
  })
}

// 新增保存
export function savePayTypeInfo(schoolCode, dataSource, accountType, accountName, accountNo, openBank, idNum) {
  return request({
    url: 'common/com/transfer',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      'schoolCode': schoolCode,
      'dataSource': dataSource,
      'accountType': accountType,
      'accountName': accountName,
      'accountNo': accountNo,
      'openBank': openBank,
      'idNum': idNum
    }
  })
}

// 编辑保存
export function updatePayTypeInfo(schoolCode, dataSource, accountType, accountName, accountNo, id) {
  return request({
    url: 'common/com/transfer',
    method: 'put',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      'schoolCode': schoolCode,
      'dataSource': dataSource,
      'accountType': accountType,
      'accountName': accountName,
      'accountNo': accountNo,
      'id': id
    }
  })
}

// 删除
export function personCenterAccountDelelte(id) {
  return request({
    url: 'common/com/transfer/delete',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      'id': id
    }
  })
}

// 获取绑定list
export function queryBoundUserAccount() {
  return request({
    url: '/pay/login/queryBoundUserAccount',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

// 解绑
export function unbindUserAccount(id) {
  return request({
    url: '/pay/login/unbindUserAccount',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      id: id
    }
  })
}

// export function getProjectType(id) {
//   return request({
//     url: 'pay/pay/getProjectType/' + id,
//     method: 'get',
//     headers: {
//       'Content-Type': 'application/json'
//     }
//   })
// }

// export function deleteProjectTypeInfo(id) {
//   return request({
//     url: 'pay/pay/projectType/deleteProjectType/' + id,
//     method: 'delete',
//     headers: {
//       'Content-Type': 'application/json'
//     }
//   })
// }
// export function getProjectTypeAndProjectInfoListInit(schoolCode) {
//   return request({
//     url: 'pay/pay/cos/merchant/getProjectTypeAndProjectInfoListInit/' + schoolCode,
//     method: 'get',
//     headers: {
//       'Content-Type': 'application/json'
//     }
//   })
// }
// export function getProjectTypeAndProjectInfoList(parentId) {
//   return request({
//     url: 'pay/pay/cos/merchant/getProjectTypeAndProjectInfoList/' + parentId,
//     method: 'get',
//     headers: {
//       'Content-Type': 'application/json'
//     }
//   })
// }
export function queryBankAccountInfo(bnkAcctNo) {
  return request({
    url: 'open/queryBank?bnkAcctNo=' + bnkAcctNo,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

